import React from 'react'
import { Dialog } from '@material-ui/core'
import Content from './Content'
import { useGalleryCxt } from '../../providers/GalleryProvider/hooks/useGalleryCxt'

export type GalleryModalProps = {}

export const GalleryModal = React.memo<GalleryModalProps>(({}) => {
    const {
        navigation: { target, close },
    } = useGalleryCxt()
    return (
        <React.Fragment>
            <Dialog open={!!target} onClose={close} fullScreen={true} scroll={'body'}>
                <Content />
            </Dialog>
        </React.Fragment>
    )
})

GalleryModal.displayName = 'GalleryModal'

export default GalleryModal
