import styled from 'styled-components'
import { FRow } from '../../atoms/Layout'
import { H3, P } from '../../atoms/Text'

export const Wrapper = styled.div`
    border-top: 1px solid black;
    &:last-child {
        border-bottom: 1px solid black;
    }
`

export const Title = styled(FRow)`
    padding: 8px 0;
    align-items: center;
    color: black;
`

export const Content = styled(P)`
    margin: 0 0 16px 0;
    font-weight: normal;
    font-size: 1rem;
    color: #727a87;
`

export const TitleH3 = styled(H3)`
    font-size: 1.5rem;
    color: #2e2e2e;
`
