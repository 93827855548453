import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import * as S from './styled'
import { IconButton } from 'gatsby-theme-material-ui'
import Grid from '@material-ui/core/Grid'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Box } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import { PBig } from '../../../atoms/Text'
import RenewIsHardCountWUs from '../../RenewIsHardCountWUs'
import { useGalleryCxt } from '../../../providers/GalleryProvider/hooks/useGalleryCxt'
import { useRenderBreakpoints } from '../../../utils/hooks/render'

export type ContentProps = {}

export const Content = React.memo<ContentProps>(({}) => {
    const {
        navigation: { open, preview, target, close, prev, next, canPrev, canNext },
    } = useGalleryCxt()
    const { onTablet } = useRenderBreakpoints()
    const previews = preview(3)
    return (
        <React.Fragment>
            <S.Background>
                <IconButton
                    style={{
                        position: 'sticky',
                        left: '100%',
                        right: 0,
                        top: 0,
                        color: 'white',
                    }}
                    onClick={close}
                >
                    <CloseIcon />
                </IconButton>
                <S.Scroll>
                    <S.Content>
                        <Grid container direction={'column'} spacing={3}>
                            <Grid item>
                                <Grid container alignItems={'center'}>
                                    {onTablet(
                                        () => (
                                            <Grid item>
                                                <IconButton
                                                    style={{ color: 'white' }}
                                                    disabled={!canPrev}
                                                    onClick={prev}
                                                >
                                                    <ArrowBackIcon />
                                                </IconButton>
                                            </Grid>
                                        ),
                                        {
                                            mode: 'hide',
                                        },
                                    )}
                                    <Grid item style={{ flex: 1 }}>
                                        <div
                                            style={{
                                                height: 480,
                                            }}
                                        >
                                            <img
                                                src={target?.src}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    {onTablet(
                                        () => {
                                            return (
                                                <Grid item>
                                                    <IconButton
                                                        style={{ color: 'white' }}
                                                        disabled={!canNext}
                                                        onClick={next}
                                                    >
                                                        <ArrowForwardIcon />
                                                    </IconButton>
                                                </Grid>
                                            )
                                        },
                                        { mode: 'hide' },
                                    )}
                                </Grid>
                            </Grid>
                            {previews.length > 0 && (
                                <Grid item>
                                    <Grid container justify={'center'} spacing={1}>
                                        {previews.map((preview, i) => {
                                            return (
                                                <Grid item key={i}>
                                                    <Box
                                                        width={110}
                                                        height={110}
                                                        style={{ padding: 1 }}
                                                        border={1}
                                                        onClick={() => {
                                                            if (preview === target) return
                                                            open(preview)
                                                        }}
                                                        borderColor={
                                                            preview !== target
                                                                ? 'transparent'
                                                                : '#fff'
                                                        }
                                                    >
                                                        <img
                                                            src={preview?.src}
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item>
                                <PBig
                                    style={{
                                        color: 'white',
                                        fontWeight: 300,
                                        marginTop: 16,
                                    }}
                                >
                                    Proyecto - {target?.imageName}
                                </PBig>
                                <PBig style={{ color: 'white' }}>
                                    {target?.projectName}
                                </PBig>
                                <Grid container spacing={1} style={{ marginTop: 24 }}>
                                    {target?.categories.map((category, i) => {
                                        return (
                                            <Grid item key={i}>
                                                <Chip label={category.name} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                            <Grid item></Grid>
                            <Grid item></Grid>
                            <Grid item>
                                <RenewIsHardCountWUs theme={'dark'} />
                            </Grid>
                        </Grid>
                    </S.Content>
                </S.Scroll>
            </S.Background>
        </React.Fragment>
    )
})

Content.displayName = 'Content'

export default Content
