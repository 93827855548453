import Grid from '@material-ui/core/Grid'
import React from 'react'
import CollapsedInfo from '../CollapsedInfo'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import GalleryMenuItem from '../GalleryMenuItem'
import { useGalleryCxt } from '../../providers/GalleryProvider/hooks/useGalleryCxt'
import { useRenderBreakpoints } from '../../utils/hooks/render'
import * as S from './styled'
import { Dialog, Fade } from '@material-ui/core'
export type Project = {
    name: string
}

export type ConstructionType = {
    key: string
}

const constructionTypes: ConstructionType[] = [
    {
        key: 'construction-type.comercial',
    },
    {
        key: 'construction-type.residential',
    },
    {
        key: 'construction-type.renovation',
    },
]
export type GalleryMenuProps = {}

export const GalleryMenu = React.memo<GalleryMenuProps>(({}) => {
    const { t } = useTranslation(['gallery'])
    const { projects, filters } = useGalleryCxt()
    const { setConstructionType, setProject } = filters
    const [openFilters, setOpenFilters] = React.useState(false)

    const { onTablet } = useRenderBreakpoints()

    const renderConstructionType = (ct: ConstructionType, i: number) => {
        const key = ct.key
        return (
            <Grid item key={i}>
                <GalleryMenuItem
                    selected={filters.constructionTypes.includes(key)}
                    onClick={() => {
                        setConstructionType(key)
                    }}
                >
                    {t(key)}
                </GalleryMenuItem>
            </Grid>
        )
    }

    const renderProject = (project: Project, i: number) => {
        const key = project.name
        return (
            <Grid item key={i}>
                <GalleryMenuItem
                    selected={filters.projects.includes(key)}
                    onClick={() => {
                        setProject(key)
                    }}
                >
                    {key}
                </GalleryMenuItem>
            </Grid>
        )
    }

    return onTablet(
        ({ matches }) => {
            if (matches) {
                return (
                    <Grid container>
                        <Fade in={!openFilters}>
                            <Grid item>
                                <CollapsedInfo
                                    title={t('show-filters')}
                                    onClick={() => {
                                        setOpenFilters(true)
                                    }}
                                    borderless
                                />
                            </Grid>
                        </Fade>
                        <Dialog
                            fullScreen={true}
                            open={openFilters}
                            hideBackdrop={true}
                            PaperProps={{
                                style: {
                                    background: 'rgba(224, 235, 237, 1)',
                                    paddingTop: 16,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                },
                            }}
                        >
                            <Grid container direction={'column'} spacing={1}>
                                <Grid item>
                                    <CollapsedInfo
                                        title={t('hide-filters')}
                                        open={true}
                                        onClick={() => {
                                            setOpenFilters(false)
                                        }}
                                        borderless
                                        handleContent={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <CollapsedInfo
                                        title={t('construction-type.title')}
                                        borderless
                                        defaultValue={true}
                                    >
                                        <Grid container spacing={1}>
                                            {constructionTypes.map(
                                                renderConstructionType,
                                            )}
                                        </Grid>
                                    </CollapsedInfo>
                                </Grid>
                                <Grid item>
                                    <CollapsedInfo
                                        defaultValue={true}
                                        title={t('projects')}
                                        borderless
                                    >
                                        <Grid container spacing={1} direction='column'>
                                            {projects.map(renderProject)}
                                        </Grid>
                                    </CollapsedInfo>
                                </Grid>
                            </Grid>
                        </Dialog>
                    </Grid>
                )
            }
            return (
                <Grid container spacing={1} direction={'column'}>
                    <Grid item>
                        <CollapsedInfo
                            defaultValue={true}
                            title={t('construction-type.title')}
                            borderless
                        >
                            <Grid container spacing={1} direction={'column'}>
                                {constructionTypes.map(renderConstructionType)}
                            </Grid>
                        </CollapsedInfo>
                    </Grid>
                    <Grid item>
                        <CollapsedInfo
                            defaultValue={true}
                            title={t('projects')}
                            borderless
                        >
                            <Grid container spacing={1} direction='column'>
                                {projects.map(renderProject)}
                            </Grid>
                        </CollapsedInfo>
                    </Grid>
                </Grid>
            )
        },
        { mode: 'handle-both' },
    )
})

GalleryMenu.displayName = 'GalleryMenu'

export default GalleryMenu
