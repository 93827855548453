import React from 'react'
import * as S from './styled'

export type GalleryItemProps = {
    image: string
    onClick?(): void
}

export const GalleryItem = React.memo<GalleryItemProps>(({ image, onClick }) => {
    return (
        <S.Wrapper>
            <S.Inner onClick={onClick}>
                <S.Img src={image} />
            </S.Inner>
        </S.Wrapper>
    )
})

GalleryItem.displayName = 'GalleryItem'

export default GalleryItem
