import styled from 'styled-components'
import { FCol } from '../../../atoms/Layout'

export const Background = styled.div`
    background-color: #000;
    min-height: 100%;
    position: relative;
    color: white;
`
export const Scroll = styled.div`
    flex: 1;
`

export const Content = styled(FCol)`
    max-width: 900px;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 0 32px 32px;
`
