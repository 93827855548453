import Grid from '@material-ui/core/Grid'
import React from 'react'
import GalleryItem from '../../molecules/GalleryItem'
import sampleImg from '../../images/wall-paint.jpg'
import GalleryMenu from '../../molecules/GalleryMenu'
import Content from '../../atoms/Content'
import { Bg } from '../../atoms/Bg'
import { SpaceV } from '../ChatPreview/styled'
import GalleryModal from '../../molecules/GalleryModal'
import { useGalleryCxt } from '../../providers/GalleryProvider/hooks/useGalleryCxt'
import { useRenderBreakpoints } from '../../utils/hooks/render'

export type GalleryProps = {}

export const Gallery = React.memo<GalleryProps>(({}) => {
    const { filteredGallery, navigation } = useGalleryCxt()
    const { onTablet } = useRenderBreakpoints()
    return (
        <React.Fragment>
            {onTablet(
                ({ matches }) => (
                    <Bg color={'white'}>
                        <Content useMaxWidth expanded usePaddingH={matches}>
                            <SpaceV s={64} />
                            <Grid
                                container
                                spacing={2}
                                direction={matches ? 'column' : 'row'}
                            >
                                <Grid item xs={matches ? false : 2}>
                                    <GalleryMenu />
                                </Grid>
                                <Grid item style={{ flex: 1 }}>
                                    <Grid container spacing={2}>
                                        {filteredGallery.map((item, i) => {
                                            return (
                                                <Grid item key={i} xs={matches ? 12 : 3}>
                                                    <GalleryItem
                                                        image={item.src}
                                                        onClick={() => {
                                                            navigation.open(item)
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Content>
                    </Bg>
                ),
                { mode: 'handle-both' },
            )}
            <SpaceV s={128} />
            <GalleryModal />
        </React.Fragment>
    )
})

Gallery.displayName = 'Gallery'

export default Gallery
