import React from 'react'
import styled from 'styled-components'
import { ButtonBase, ButtonBaseProps } from '@material-ui/core'

export type GalleryMenuItemProps = {
    selected?: boolean
} & ButtonBaseProps

const Wrapper = styled(ButtonBase)<Pick<GalleryMenuItemProps, 'selected'>>`
    padding: 10px 12px;
    border-radius: 30px;
    background-color: ${(props) => (props.selected ? '#c7c7c7' : 'white')};
    border: 1px solid #f2f2f2;
    transition: all 0.3s ease-in-out;
`

export const GalleryMenuItem: React.FC<GalleryMenuItemProps> =
    React.memo<GalleryMenuItemProps>(({ selected, children, ...rest }) => {
        return (
            <Wrapper selected={selected} {...rest}>
                {children}
            </Wrapper>
        )
    })

GalleryMenuItem.displayName = 'GalleryMenuItem'

export default GalleryMenuItem
