import React from 'react'
import { graphql } from 'gatsby'
import Header from '../../molecules/Header'
import Footer from '../../organisms/Footer'
import { ClientReviewType } from '../../molecules/ClientReview'
import Gallery from '../../organisms/Gallery'
import GalleryProvider from '../../providers/GalleryProvider'

export const clientReviews: ClientReviewType[] = Array(2).fill({
    clientFullName: 'Sara C.',
    rate: 5,
    title: 'Conamer se encargó de todo!',
    comment:
        'Conamer se encargó de nuestro tocador (medio baño) que requería una actualización. La experiencia fue estupenda al saber que se encargaron del presupuesto, del diseño (seguimos modificando y nos ayudaron con nuestras muchas preguntas a lo largo del camino), de encontrar al contratista y de asegurar la entrega de todos los suministros. Fue muy fácil trabajar con una sola empresa para todas las partes de nuestra renovación.',
    categories: [{ name: 'Caracas' }, { name: 'Renovación de baño' }],
    gallery: Array(5).fill({
        src: '',
    }),
} as ClientReviewType)
export type ReviewsProps = {}

export const GalleryPage = React.memo<ReviewsProps>(({ ...props }) => {
    console.debug('PROPS', props)
    return (
        <main>
            <Header />
            <GalleryProvider>
                <Gallery />
            </GalleryProvider>
            <Footer />
        </main>
    )
})

GalleryPage.displayName = 'GalleryPage'

export default GalleryPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
