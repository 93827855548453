import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import EstimateButton from '../../organisms/EstimateButton'
import Grid from '@material-ui/core/Grid'
import { PBig } from '../../atoms/Text'

export type RenewIsHardCountWUsProps = {
    theme?: 'light' | 'dark'
}

export const RenewIsHardCountWUs = React.memo<RenewIsHardCountWUsProps>(
    ({ theme = 'light' }) => {
        const { t } = useTranslation('index')
        return (
            <React.Fragment>
                <Grid
                    container
                    alignItems={'center'}
                    spacing={2}
                    style={{ width: '100%' }}
                    justify={'space-evenly'}
                >
                    <Grid item>
                        <PBig style={theme === 'dark' ? { color: 'white' } : {}}>
                            {t('countWithUs.title.part1')} {t('countWithUs.title.part2')}
                        </PBig>
                    </Grid>
                    <Grid item>
                        <EstimateButton theme={theme} />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    },
)

RenewIsHardCountWUs.displayName = 'RenewIsHardCountWUs'

export default RenewIsHardCountWUs
