import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    padding-top: 100%;
    position: relative;
`

export const Inner = styled.div`
    position: absolute;
    inset: 0;
    background: #c4c4c4;
    border-radius: 5px;
    overflow: hidden;
`

export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
