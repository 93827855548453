import React from 'react'
import { H3, P } from '../../atoms/Text'
import { Collapse } from '@material-ui/core'
import styled from 'styled-components'
import { FExpand, FRow } from '../../atoms/Layout'
import { IconButton } from 'gatsby-theme-material-ui'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import * as S from './styled'

export type CollapsedInfoProps = {
    title: string
    borderless?: boolean
    open?: boolean
    onClick?(): void
    handleContent?: boolean
    defaultValue?: boolean
}

export const CollapsedInfo: React.FC<CollapsedInfoProps> = React.memo<CollapsedInfoProps>(
    ({
        title,
        onClick,
        handleContent,
        open: handleOpen,
        children: content,
        borderless,
        defaultValue = false,
    }) => {
        const [open, setOpen] = React.useState(defaultValue)
        return (
            <S.Wrapper style={borderless ? { border: 'none' } : {}}>
                <S.Title onClick={onClick || (() => setOpen((p) => !p))}>
                    <S.TitleH3>{title}</S.TitleH3>
                    <FExpand />
                    <IconButton style={{ color: 'black' }}>
                        {handleOpen || open ? <RemoveIcon /> : <AddIcon />}
                    </IconButton>
                </S.Title>
                {!handleContent && (
                    <Collapse in={handleOpen || open}>
                        <S.Content>{content}</S.Content>
                    </Collapse>
                )}
            </S.Wrapper>
        )
    },
)

CollapsedInfo.displayName = 'CollapsedInfo'

export default CollapsedInfo
