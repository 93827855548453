import { sBreakpoints } from '../../constants'
import styled from 'styled-components'
import Content from '../../atoms/Content'
import { Bg } from '../../atoms/Bg'

export const SBg = styled(Bg)`
    align-items: center;
`

export const SContent = styled(Content)`
    ${sBreakpoints.notTablet} {
        padding-left: 64px;
        padding-right: 64px;
    }
`

export const SpaceV128 = styled.div`
    margin-top: 128px;
`

export const SpaceV16 = styled.div`
    margin-top: 16px;
`

export const SpaceV = styled.div<{ s: number }>`
    margin-top: ${(props) => props.s}px;
`
